import React from "react"
import { Trans, useTranslation } from "react-i18next"

import { Container } from "../components/container"
import SEO from "../components/seo"

const DSGVOPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <SEO
        title="Datenschutz"
      />
      <Container className="terms">
        <h1 className="text-center">
        {t("DSGVOPage.title")}
        </h1>
        <section></section>
      </Container>
    </>
  )
}

export default DSGVOPage